<template>
  <div>
    <van-dropdown-menu>
      <van-dropdown-item v-model="value1" :options="option1" />
      <van-dropdown-item v-model="value2" :options="option2" />
    </van-dropdown-menu>
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);
export default {
  props: ["deviceType"],
  data() {
    return {
      value1: "YSY001",
      value2: 0,
      option1: [],
      option2: [
        { text: "全部", value: 0 },
        { text: "当天", value: 1 },
      ],
    };
  },
  mounted() {
    let that = this;
    that.axios
      .post("Base_Device/GetDeviceTypeByCompanyName")
      .then(function (response) {
        that.option1 = response.data.data;
      });
  },
};
</script>

<style>
</style>